import { Subscription } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { SplashScreenService } from './core/_base/layout';
import { ConnectionService } from 'ng-connection-service';
// CRUD
import { InterceptService } from './core/_base/crud';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  title = 'viaDOTS Receipts';
  loader: boolean = true;
  private unsubscribe: Subscription[] = [];
  noInternetConnection: boolean;
  isConnected = true;

  constructor(private router: Router,
              private splashScreenService: SplashScreenService,
              private connectionService: ConnectionService,
              private interceptService: InterceptService,
              private cdr: ChangeDetectorRef) {
                this.interceptService.getClientIp();
                this.unsubscribe.push(this.connectionService.monitor().subscribe(isConnected => {
                  this.isConnected = isConnected;
                  console.log("Network connection status : " + this.isConnected);
                  if (isConnected) {
                    this.noInternetConnection = false;
                  }
                  else {
                    this.noInternetConnection = true;
                  }
                  this.cdr.detectChanges();
                }));
              }

  ngOnInit(): void {
    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('kt-page--loaded');
        }, 500);
      }
    });

    this.unsubscribe.push(routerSubscription);
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
