import { RideReceiptModule } from './views/pages/ride-receipt/ride-receipt.module';
// Angular
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ErrorPageComponent } from "./views/partials/general/error-page/error-page.component";
import { SuccessPageComponent } from "./views/partials/general/success-page/success-page.component";


const routes: Routes = [
  //
  {
    path: '',
    redirectTo: 'ride-receipt',
    pathMatch: 'full'
  },
  // Open page for invoice
  {
    path: "ride-receipt",
    loadChildren: () =>
      import("./views/pages/ride-receipt/ride-receipt.module").then(m => m.RideReceiptModule)
  },
  // Extra Routes Without Auth Guard
  {
    path: "error/:code",
    component: ErrorPageComponent
  },
  {
    path: "success/:code",
    component: SuccessPageComponent
  },
  {
    path: "**",
    redirectTo: "error/404",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
