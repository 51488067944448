import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { LocationStrategy } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// Toast
import { ToastrModule } from 'ngx-toastr';
// Environment Variables
import { environment } from '../environments/environment';

import { NgbNavModule, NgbAccordionModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
// Material
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { from } from 'rxjs';
import { CoreModule } from "./core/core.module";
// Partials
import { PartialsModule } from './views/partials/partials.module';
// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions';


import { AsyncPipe } from '@angular/common';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Services
import { InterceptService } from './core/_base/crud';

// Layout Services
import {
  LayoutConfigService,
  SplashScreenService,
} from './core/_base/layout';

import { ConnectionServiceModule } from 'ng-connection-service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    AppRoutingModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    PartialsModule,
    CoreModule,
    ScrollToModule.forRoot(),
    ToastrModule.forRoot({
      maxOpened: 3,
      newestOnTop: false,
      preventDuplicates: true,
      //countDuplicates: true,
      positionClass: 'toast-bottom-right',
      progressBar: true,
    }),
    PartialsModule,
    MatDialogModule,
    ConnectionServiceModule,
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: 'app-mat-dialog-container__wrapper',
        height: 'auto'
      }
    },
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    },
    SplashScreenService,
    AsyncPipe
  ],
  bootstrap: [AppComponent],
  // schemas: [
  //   CUSTOM_ELEMENTS_SCHEMA
  // ],
})
export class AppModule { }
