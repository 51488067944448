<div class="modal-header border-bottom-0 mb-0 pb-0">
  <button type="button" class="close" aria-hidden="true" (click)="onCancel()">×</button>
</div>
<div class="modal-body p-4 pt-0 text-center">
  <div class="icon-box-c icon-{{data.type}}">
    <i class="fas {{getIcon(data.type)}}"></i>
  </div>
  <h5 class="modal-title font-size-16 mt-2 mb-1 text-dark text-center">{{data.title}}</h5>
  <p class="font-size-4 text-dark text-center">{{data.description}}</p>
  <div class="row">
    <div class="col-12">
      <div class="col-md-12 text-center">
        <button type="button" class="btn btn-secondary w-sm" (click)="onCancel()">
          {{data.cancelButton}}
        </button>
        <button *ngIf="data.confirmButton" type="button" class="btn btn-primary w-sm ml-3" (click)="onConfirm()" [disabled]="viewLoading">
          {{data.confirmButton}}
          <i *ngIf="viewLoading" class="bx bx-loader bx-spin"></i>
        </button>
      </div>
    </div>
  </div>
</div>
