<div class="account-pages my-5 pt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center mb-5">
                    <h1 class="display-2 font-weight-medium">{{code}}</h1>
                    <h4 class="text-uppercase">{{title}}</h4>
                    <p style="padding: 0px 200px;">{{subtitle}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
