// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';
import { tap, map, mergeMap, catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
// Layout
// import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud/';
import { environment } from '../../../../../environments/environment';

const API_URL = environment.apiUrl + '/auth';


/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

  constructor(private router: Router,
    private http: HttpClient) {}

  clientIp: string = '';
  clientMac: string = '';

  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.url.includes('https://geolocation-db.com/json/')) {
      request = request.clone({});
    } else {
      // Predefined headers
      this.clientIp = localStorage.getItem(environment.clientIpv4);
      this.clientMac = localStorage.getItem(environment.clientMac);
      request = request.clone({
        setHeaders: {
          'clientip4': `${this.clientIp}`,
          'clientmac': `${this.clientMac}`,
          'versioncode': `${environment.versionCode}`,
          'versionname': `${environment.versionName}`,
          'platform': 'web',
          'version': 'v4',
        }
      });
    }

    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            if (event.status === 200) {
              //
            }
          }
        },
        error => {
          if (error.status === 401) {
            //
          }
        }
      )
    );
  }

  getClientIp() {
    return this.http.get<any>('https://geolocation-db.com/json/').subscribe(
      res => {
        console.log(res);
        if (res.IPv4) {
          localStorage.setItem(environment.clientIpv4, res.IPv4);
        }else {
          localStorage.setItem(environment.clientIpv4, 'unknown');
          return this.clientIp;
        }
      }
    );
  }
}
