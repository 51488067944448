// Angular
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// Object-Path
//import * as objectPath from 'object-path';
// Layout
import { SplashScreenService } from '../../../../core/_base/layout';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {

  	// Public proprties
	loaderLogo: string = './assets/preloader/loader-1.gif';
	loaderMessage: string = 'Loading...';

	@ViewChild('splashScreen', {static: true}) splashScreen: ElementRef;

	constructor(
		private el: ElementRef,
		private splashScreenService: SplashScreenService) {
	}

	ngOnInit() {
		this.splashScreenService.init(this.splashScreen);
	}

}

